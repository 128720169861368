.hourDisplay {
    flex: 2;
    padding: 0 2rem;
    text-align: left;
    font-weight: 300;
    max-width: 400px;
}

.forecastItem {
    text-align: left;
    flex: 1;
}

.forecastHourRow {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.forecastHourRow:not(:first-child) {
    border-top: 1px solid #f0f0f0;
}