.App {
  text-align: center;
}

.header {
  background-color: #365697;
  text-align: left;
  padding: 20px 40px;
  font-size: calc(20px + 2vmin);
  font-family:Arial, Helvetica, sans-serif;
  color: white;
}

.App-link {
  color: #61dafb;
}
